import React from 'react';
import {Router, Switch, Link, Redirect, Route} from 'react-router-dom';
import {connect} from "react-redux";
import {Layout, Menu, Drawer, Avatar, Tag, Row, Col, Typography, Dropdown, Form, Spin, ConfigProvider} from 'antd';
import {MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined} from '@ant-design/icons';
import classNames from "classnames";
import {history} from "../../history";
import loadable from '@loadable/component';

import SidebarMenu from "./SidebarMenu";
import OverlaySpinner from "../overlay-spinner/OverlaySpinner";
import * as helper from "../../utils/helper";
import store from "../../store";
import {FETCH_CURRENT_USER_STOP, TOGGLE_NOTICE_MODAL} from "../../actions/types";
import PrivateRoute from "../common/PrivateRoute";

import logo_sm_url from '../../assets/img/logo_sm.jpg';
import TrialBalanceReport3 from "../report/TrialBalanceReport3";
import * as constants from "../../utils/constants";

const {Header, Sider, Content, Footer} = Layout;
const {SubMenu} = Menu;

const ItemOrderDeliveryChargeReport = loadable(() => import('../item_order/ItemOrderDeliveryChargeReport'), {fallback: <OverlaySpinner/>})
const ItemOrderDebtorReport = loadable(() => import('../item_order/ItemOrderDebtorReport'), {fallback: <OverlaySpinner/>})
const ItemOrderIncentiveList = loadable(() => import('../item_order/ItemOrderIncentiveList'), {fallback: <OverlaySpinner/>})
const ItemOrderDeliveryList = loadable(() => import('../item_order/ItemOrderDeliveryList'), {fallback: <OverlaySpinner/>})
const ItemOrderList = loadable(() => import('../item_order/ItemOrderList'), {fallback: <OverlaySpinner/>})
const ItemOrderPaymentList = loadable(() => import('../item_order/ItemOrderPaymentList'), {fallback: <OverlaySpinner/>})
const PartyConfirmationLetterPrint = loadable(() => import('../report/PartyConfirmationLetterPrint'), {fallback: <OverlaySpinner/>})
const ItemDemandList = loadable(() => import('../item_demand/ItemDemandList'), {fallback: <OverlaySpinner/>})
const LogoutModal = loadable(() => import('./LogoutModal'), {fallback: <OverlaySpinner/>})
const OfficeList = loadable(() => import('../office/OfficeList'), {fallback: <OverlaySpinner/>})
const ItemGroupList = loadable(() => import('../item/ItemGroupList'), {fallback: <OverlaySpinner/>})
const ItemCategoryList = loadable(() => import('../item/ItemCategoryList'), {fallback: <OverlaySpinner/>})
const ItemUnitList = loadable(() => import('../item/ItemUnitList'), {fallback: <OverlaySpinner/>})
const ItemList = loadable(() => import('../item/ItemList'), {fallback: <OverlaySpinner/>})
const FiscalYearList = loadable(() => import('../fiscal_year/FiscalYearList'), {fallback: <OverlaySpinner/>})
const VoucherList = loadable(() => import('../account/VoucherList'), {fallback: <OverlaySpinner/>})
const PurchaseList = loadable(() => import('../purchase/PurchaseList'), {fallback: <OverlaySpinner/>})
const PurchaseDetail = loadable(() => import('../purchase/PurchaseDetail'), {fallback: <OverlaySpinner/>})
const BankAccountList = loadable(() => import('../bank_account/BankAccountList'), {fallback: <OverlaySpinner/>})
const StockTransferCreate = loadable(() => import('../transaction/StockTransferCreate'), {fallback: <OverlaySpinner/>})
const SaleDetail = loadable(() => import('../sale/SaleDetail'), {fallback: <OverlaySpinner/>})
const StaffList = loadable(() => import('../staff/StaffList'), {fallback: <OverlaySpinner/>})
const ItemStockSummaryReport = loadable(() => import('../report/ItemStockSummaryReport'), {fallback: <OverlaySpinner/>})
const DayClose = loadable(() => import('../account/DayClose'), {fallback: <OverlaySpinner/>})
const DepartmentList = loadable(() => import('../staff/department/DepartmentList'), {fallback: <OverlaySpinner/>})
const StaffGroupList = loadable(() => import('../staff/staff_group/StaffGroupList'), {fallback: <OverlaySpinner/>})
const StaffDesignationList = loadable(() => import('../staff/staff_designation/StaffDesignationList'), {fallback: <OverlaySpinner/>})
const JobTypeList = loadable(() => import('../staff/job_type/JobTypeList'), {fallback: <OverlaySpinner/>})
const TrialBalanceReport = loadable(() => import('../report/TrialBalanceReport'), {fallback: <OverlaySpinner/>})
const JBLOfficeLedger = loadable(() => import('../report/JBLOfficeLedger'), {fallback: <OverlaySpinner/>})
const JblOfficeReceiptCreate = loadable(() => import('../transaction/JblOfficeReceiptCreate'), {fallback: <OverlaySpinner/>})
const SalesBillRegisterReport = loadable(() => import('../report/SalesBillRegisterReport'), {fallback: <OverlaySpinner/>})
const ReceiptCreate = loadable(() => import('../transaction/ReceiptCreate'), {fallback: <OverlaySpinner/>})
const CustomerList = loadable(() => import('../customer/CustomerList'), {fallback: <OverlaySpinner/>})
const SupplierList = loadable(() => import('../supplier/SupplierList'), {fallback: <OverlaySpinner/>})
const CustomerOpeningBalanceList = loadable(() => import('../customer/CustomerOpeningBalanceList'), {fallback: <OverlaySpinner/>})
const CustomerLedger = loadable(() => import('../report/CustomerLedger'), {fallback: <OverlaySpinner/>})
const JblOfficePaymentCreate = loadable(() => import('../transaction/JblOfficePaymentCreate'), {fallback: <OverlaySpinner/>})
const CustomerLedgerBalance = loadable(() => import('../report/CustomerLedgerBalance'), {fallback: <OverlaySpinner/>})
const UserPasswordChangeModal = loadable(() => import('../user/UserPasswordChangeModal'), {fallback: <OverlaySpinner/>})
const DenominationCalculator = loadable(() => import('../misc/DenominationCalculator'), {fallback: <OverlaySpinner/>})
const DenominationCalculatorModal = loadable(() => import('../misc/DenominationCalculatorModal'), {fallback: <OverlaySpinner/>})
const StockRegisterReport = loadable(() => import('../report/StockRegisterReport'), {fallback: <OverlaySpinner/>})
const SupplierOpeningBalanceEdit = loadable(() => import('../supplier/SupplierOpeningBalanceEdit'), {fallback: <OverlaySpinner/>})
const SupplierOpeningBalanceList = loadable(() => import('../supplier/SupplierOpeningBalanceList'), {fallback: <OverlaySpinner/>})
const AccountLedgerOpeningBalanceList = loadable(() => import('../account/AccountLedgerOpeningBalanceList'), {fallback: <OverlaySpinner/>})
const AccountLedgerOpeningBalanceEdit = loadable(() => import('../account/AccountLedgerOpeningBalanceEdit'), {fallback: <OverlaySpinner/>})
const ItemStockOpeningBalanceEdit = loadable(() => import('../item/ItemStockOpeningBalanceEdit'), {fallback: <OverlaySpinner/>})
const ItemStockOpeningBalanceList = loadable(() => import('../item/ItemStockOpeningBalanceList'), {fallback: <OverlaySpinner/>})
const CustomerOpeningBalanceEdit = loadable(() => import('../customer/CustomerOpeningBalanceEdit'), {fallback: <OverlaySpinner/>})
const JblOfficeOpeningBalanceEdit = loadable(() => import('../office/JblOfficeOpeningBalanceEdit'), {fallback: <OverlaySpinner/>})
const JblOfficeOpeningBalanceList = loadable(() => import('../office/JblOfficeOpeningBalanceList'), {fallback: <OverlaySpinner/>})
const LedgerDetailReport = loadable(() => import('../report/LedgerDetailReport'), {fallback: <OverlaySpinner/>})
const IbtCashBankTransfer = loadable(() => import('../transaction/IbtCashBankTransfer'), {fallback: <OverlaySpinner/>})
const PaymentVoucherDetail = loadable(() => import('../transaction/PaymentVoucherDetail'), {fallback: <OverlaySpinner/>})
const StockTransferReport = loadable(() => import('../report/StockTransferReport'), {fallback: <OverlaySpinner/>})
const AccountVoucherReport = loadable(() => import('../report/AccountVoucherReport'), {fallback: <OverlaySpinner/>})
const PurchaseRegisterReport = loadable(() => import('../report/PurchaseRegisterReport'), {fallback: <OverlaySpinner/>})
const IncentiveRegisterReport = loadable(() => import('../report/IncentiveRegisterReport'), {fallback: <OverlaySpinner/>})
const DayCloseReport = loadable(() => import('../report/DayCloseReport'), {fallback: <OverlaySpinner/>})
const IbtRegisterReport = loadable(() => import('../report/IbtRegisterReport'), {fallback: <OverlaySpinner/>})
const ItemIncentiveList = loadable(() => import('../item/ItemIncentiveList'), {fallback: <OverlaySpinner/>})
const DayBookRegisterReport = loadable(() => import('../report/DayBookRegisterReport'), {fallback: <OverlaySpinner/>})
const DashboardPage = loadable(() => import('../misc/DashboardPage'), {fallback: <OverlaySpinner/>})
const IbtDetail = loadable(() => import('../account/IbtDetail'), {fallback: <OverlaySpinner/>})
const AuditLogReport = loadable(() => import('../report/AuditLogReport'), {fallback: <OverlaySpinner/>})
const PaymentCreate = loadable(() => import('../transaction/PaymentCreate'), {fallback: <OverlaySpinner/>})
const ItemMovementRegister = loadable(() => import('../report/ItemMovementRegister'), {fallback: <OverlaySpinner/>})
const SalesVatRegisterReport = loadable(() => import('../report/SalesVatRegisterReport'), {fallback: <OverlaySpinner/>})
const PurchaseVatRegisterReport = loadable(() => import('../report/PurchaseVatRegisterReport'), {fallback: <OverlaySpinner/>})
const NoticeList = loadable(() => import('../utility/NoticeList'), {fallback: <OverlaySpinner/>})
const NoticeListModal = loadable(() => import('../utility/NoticeListModal'), {fallback: <OverlaySpinner/>})
const SalesReturnRegisterReport = loadable(() => import('../report/SalesReturnRegisterReport'), {fallback: <OverlaySpinner/>})
const StockDemandList = loadable(() => import('../office/StockDemandList'), {fallback: <OverlaySpinner/>})
const JblHomeApplianceDisbursedLoanList = loadable(() => import('../report/JblHomeApplianceDisbursedLoanList'), {fallback: <OverlaySpinner/>})
const JblHomeApplianceDemandLoanList = loadable(() => import('../report/JblHomeApplianceDemandLoanList'), {fallback: <OverlaySpinner/>})
const DatabaseBackupList = loadable(() => import('../utility/DatabaseBackupList'), {fallback: <OverlaySpinner/>})
const PurchaseReturnCreate = loadable(() => import('../supplier/PurchaseReturnCreate'), {fallback: <OverlaySpinner/>})
const ProfitLossReport = loadable(() => import('../report/ProfitLossReport'), {fallback: <OverlaySpinner/>})
const BalanceSheetReport = loadable(() => import('../report/BalanceSheetReport'), {fallback: <OverlaySpinner/>})
const HoBranchIbtReconciliationReport = loadable(() => import('../report/HoBranchIbtReconciliationReport'), {fallback: <OverlaySpinner/>})
const CustomerReceiptCreate = loadable(() => import('../customer/CustomerReceiptCreate'), {fallback: <OverlaySpinner/>})
const TerminalList = loadable(() => import('../office/terminal/TerminalList'), {fallback: <OverlaySpinner/>})
const GoDownList = loadable(() => import('../office/godown/GoDownList'), {fallback: <OverlaySpinner/>})
const SalesReturnCreate = loadable(() => import('../customer/SalesReturnCreate'), {fallback: <OverlaySpinner/>})
const SalesMaterialisedList = loadable(() => import('../report/SalesMaterialisedList'), {fallback: <OverlaySpinner/>})
const PurchaseInvoiceCreate = loadable(() => import('../supplier/PurchaseInvoiceCreate'), {fallback: <OverlaySpinner/>})
const AccountTreeList = loadable(() => import('../account/AccountTreeList'), {fallback: <OverlaySpinner/>})
const AccountMainLedgerReport = loadable(() => import('../report/AccountMainLedgerReport'), {fallback: <OverlaySpinner/>})
const AccountSubLedgerReport = loadable(() => import('../report/AccountSubLedgerReport'), {fallback: <OverlaySpinner/>})
const CustomerIncentiveExpenseReport = loadable(() => import('../report/CustomerIncentiveExpenseReport'), {fallback: <OverlaySpinner/>})
const JblBillPaymentReport = loadable(() => import('../report/JblBillPaymentReport'), {fallback: <OverlaySpinner/>})

class DefaultLayout extends React.Component {
  state = {
    is_collapsed: false,
    is_mobile_view: false,
    is_drawer_visible: false,
    show_logout_modal: false,
    show_change_password_modal: false,
    show_denomination_calculator_modal: false,
    // show_notice_list_modal: true,
  };

  /*  toggle = () => {
      this.setState({
        collapsed: !this.state.collapsed,
      });
    };*/

  componentDidMount() {
    window.addEventListener("resize", this.update_view);

    this.update_view();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.update_view);
  }

  /*  updateViewState = () => {
      if (!this.state.is_mobile_view && document.documentElement.clientWidth < 1024) {
        this.setState({
          is_mobile_view: true,
          collapsed: false
        });
      } else if (document.documentElement.clientWidth > 1024) {
        this.setState({
          is_mobile_view: false,
          // collapsed: false
        });
      }
    };*/

  update_view = () => {
    if (!this.state.is_mobile_view && document.documentElement.clientWidth < 1024) {
      this.setState({
        is_mobile_view: true,
      });
    } else if (document.documentElement.clientWidth > 1024) {
      this.setState({
        is_mobile_view: false,
        is_drawer_visible: false,
      });
    }
  };

  show_drawer = () => {
    this.setState({
      is_drawer_visible: true,
    });
  };

  render_user_avatar = () => {
    let {staff} = this.props;

    return (
      <span>
        {staff.profile_image_name && staff.profile_image_name !== '' && <Avatar src={`${helper.get_base_url()}${staff.profile_image_url}`} style={{marginRight: "5px"}}/>}
        <span>{staff.first_name + ' ' + staff.last_name}</span>
      </span>
    );
  };

  render() {
    if (this.props.is_fetching_user) {
      return <OverlaySpinner message="Please wait..."/>
    }

    return (
      <Router history={history}>
        {/*<Layout style={{height: '100vh', overflow: 'auto'}}>*/}
        <ConfigProvider
          transition={{duration: 0}}
          // theme={{
          //   components: {
          //     Menu: {
          //       itemHeight: 25,
          //     }
          //   }
          // }}
        >
          <Layout>
            {this.state.show_logout_modal
            && <LogoutModal
              onCancel={(e) => {
                this.setState({show_logout_modal: false})
              }}
              closeModal={(e) => {
                this.setState({show_logout_modal: false})
              }}/>}

            {(this.state.show_change_password_modal || this.props.show_password_change_modal)
            && <UserPasswordChangeModal
              onCancel={(e) => {
                this.setState({show_change_password_modal: false})
              }}
              closeModal={(e) => {
                this.setState({show_change_password_modal: false})
              }}/>}

            {this.state.show_denomination_calculator_modal
            && <DenominationCalculatorModal
              onCancel={(e) => {
                this.setState({show_denomination_calculator_modal: false})
              }}
              closeModal={(e) => {
                this.setState({show_denomination_calculator_modal: false})
              }}/>}

            {this.props.show_notice_modal
            && <NoticeListModal
              onCancel={(e) => {
                store.dispatch({type: TOGGLE_NOTICE_MODAL});
                // this.setState({show_notice_list_modal: false})
              }}
              closeModal={(e) => {
                store.dispatch({type: TOGGLE_NOTICE_MODAL});
                // this.setState({show_notice_list_modal: false})
              }}/>}

            <Drawer
              placement="left"
              closable={false}
              onClose={() => {
                this.setState({is_drawer_visible: false})
              }}
              visible={this.state.is_drawer_visible}
              bodyStyle={{padding: 0}}
            >
              <SidebarMenu is_mobile_view={this.state.is_mobile_view}/>
            </Drawer>

            <Header style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              position: 'fixed',
              zIndex: 999,
              width: '100%',
              background: "#40a9ff"
            }}>
              <div className="san-logo-wrapper" style={{
                textAlign: "center",
                margin: 0,
                padding: 0,
                // width: '200px',
              }}>
                <h3>
                  {this.state.is_collapsed ? <Link to="/">AM</Link> :
                    <Link to='/'>
                      <img alt="Amahi Mart" src={logo_sm_url} style={{height: '58px'}}/>
                    </Link>
                  }
                </h3>
              </div>

              {this.state.is_mobile_view ?
                this.state.is_collapsed ?
                  <MenuFoldOutlined className="trigger" style={{fontSize: "24px", lineHeight: "50px", color: "white"}} onClick={this.show_drawer}/> :
                  <MenuFoldOutlined className="trigger" style={{fontSize: "24px", lineHeight: "50px", color: "white"}} onClick={this.show_drawer}/>
                :
                <SidebarMenu is_mobile_view={this.state.is_mobile_view}/>
              }

              <div style={{marginRight: "0", paddingRight: "16px", color: "white"}}>
                <Dropdown trigger={['click']} overlay={
                  <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} className="header-menu">
                    <Menu.Item key="denomination_calculator"
                               onClick={() => {
                                 this.setState({show_denomination_calculator_modal: true})
                               }}>
                      Denomination Calculator
                    </Menu.Item>

                    <Menu.Item key="change_password" onClick={() => {
                      this.setState({show_change_password_modal: true})
                    }}>Change Password</Menu.Item>

                    <Menu.Item key="user_logout" onClick={() => {
                      this.setState({show_logout_modal: true})
                    }}>Logout</Menu.Item>
                  </Menu>
                }>
                  <span onClick={(e) => e.preventDefault()} className="cursor-pointer">
                    {this.render_user_avatar()} <DownOutlined/>
                  </span>
                </Dropdown>
              </div>
            </Header>

            <Layout style={{padding: "60px 0px 0px"}}>
              <Layout>
                <Content className="san-content">

                  {this.props.user_type === constants.USER_TYPE_AMAHI_MART ? <div style={{padding: "15px 20px 0 20px"}} ref={node => this.main_div = node} tabIndex="-1">
                    <Form
                      name="advanced_search"
                      className="ant-advanced-search-form office-info-box"
                    >
                      <Row gutter={24}>
                        <Col sm={12} md={6}>
                          <Form.Item label="Office">
                            <Typography.Text
                              strong>{`${this.props.staff.office_code} - ${this.props.staff.office_name}`}</Typography.Text>
                          </Form.Item>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Item label="Staff">
                            <Typography.Text
                              strong>{`${this.props.staff.staff_code} - ${this.props.staff.staff_name}`}</Typography.Text>
                          </Form.Item>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Item label="Fiscal Year">
                            <Typography.Text strong>{this.props.active_fiscal_year.fiscal_year_name}</Typography.Text>
                          </Form.Item>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Item label="Transaction Date">
                            <Typography.Text strong>{this.props.day_closure_bs_date} ({this.props.day_closure_day_name})</Typography.Text>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div> : null}

                  <Switch>

                    <PrivateRoute exact path="/dashboard/" component={DashboardPage}/>
                    <PrivateRoute exact path="/office/list/" component={OfficeList}/>
                    <PrivateRoute exact path="/office/godown/list/" component={GoDownList}/>
                    <PrivateRoute exact path="/office/terminal/list/" component={TerminalList}/>

                    <PrivateRoute exact path="/jbl_office/opening_balance/list/" component={JblOfficeOpeningBalanceList}/>
                    <PrivateRoute exact path="/jbl_office/opening_balance/edit/" component={JblOfficeOpeningBalanceEdit}/>
                    <PrivateRoute exact path="/jbs_office/receipt/create/" component={JblOfficeReceiptCreate}/>

                    <PrivateRoute exact path="/department/list/" component={DepartmentList}/>
                    <PrivateRoute exact path="/staff_group/list/" component={StaffGroupList}/>
                    <PrivateRoute exact path="/staff_designation/list/" component={StaffDesignationList}/>
                    <PrivateRoute exact path="/job_type/list/" component={JobTypeList}/>

                    <PrivateRoute exact path="/staff/list/" component={StaffList}/>

                    <PrivateRoute exact path="/item/group/list/" component={ItemGroupList}/>
                    <PrivateRoute exact path="/item/category/list/" component={ItemCategoryList}/>
                    <PrivateRoute exact path="/item/unit/list/" component={ItemUnitList}/>
                    <PrivateRoute exact path="/item/list/" component={ItemList}/>
                    <PrivateRoute exact path="/item/stock_opening_balance/list/" component={ItemStockOpeningBalanceList}/>
                    <PrivateRoute exact path="/item/stock_opening_balance/edit/" component={ItemStockOpeningBalanceEdit}/>

                    <PrivateRoute exact path="/item/incentive/list/" component={ItemIncentiveList}/>

                    <PrivateRoute exact path="/customer/list/" component={CustomerList}/>
                    <PrivateRoute exact path="/customer/opening_balance/list/" component={CustomerOpeningBalanceList}/>
                    <PrivateRoute exact path="/customer/opening_balance/edit/" component={CustomerOpeningBalanceEdit}/>
                    <PrivateRoute exact path="/customer/receipt/create/" component={CustomerReceiptCreate}/>
                    <PrivateRoute exact path="/customer/sales_return/create/" component={SalesReturnCreate}/>

                    <PrivateRoute exact path="/supplier/list/" component={SupplierList}/>
                    <PrivateRoute exact path="/supplier/opening_balance/list/" component={SupplierOpeningBalanceList}/>
                    <PrivateRoute exact path="/supplier/opening_balance/edit/" component={SupplierOpeningBalanceEdit}/>
                    <PrivateRoute exact path="/supplier/purchase_return/create/" component={PurchaseReturnCreate}/>
                    <PrivateRoute exact path="/supplier/purchase_invoice/create/" component={PurchaseInvoiceCreate}/>

                    <PrivateRoute exact path="/fiscal_year/list/" component={FiscalYearList}/>

                    <PrivateRoute exact path="/account/tree/list/" component={AccountTreeList}/>

                    <PrivateRoute exact path="/account/opening_balance/list/" component={AccountLedgerOpeningBalanceList}/>
                    <PrivateRoute exact path="/account/opening_balance/edit/" component={AccountLedgerOpeningBalanceEdit}/>

                    <PrivateRoute exact path="/account/voucher/list/" component={VoucherList}/>
                    <PrivateRoute exact path="/account/day_close/" component={DayClose}/>
                    <PrivateRoute exact path="/account/ibt/:transaction_id/detail/" component={IbtDetail}/>

                    <PrivateRoute exact path="/bank_account/list/" component={BankAccountList}/>

                    <PrivateRoute exact path="/purchase/list/" component={PurchaseList}/>
                    <PrivateRoute exact path="/purchase/:purchase_id/detail" component={PurchaseDetail}/>

                    <PrivateRoute exact path="/sale/:sale_id/detail" component={SaleDetail}/>

                    <PrivateRoute exact path="/report/purchase_register/" component={PurchaseRegisterReport}/>
                    <PrivateRoute exact path="/report/sales_bill_register/" component={SalesBillRegisterReport}/>
                    <PrivateRoute exact path="/report/sales_return_register/" component={SalesReturnRegisterReport}/>
                    <PrivateRoute exact path="/report/item_stock/summary/" component={ItemStockSummaryReport}/>
                    <PrivateRoute exact path="/report/jbl_office_ledger/" component={JBLOfficeLedger}/>
                    <PrivateRoute exact path="/report/customer_ledger/" component={CustomerLedger}/>
                    <PrivateRoute exact path="/report/ledger_detail/" component={LedgerDetailReport}/>
                    <PrivateRoute exact path="/report/account/voucher/" component={AccountVoucherReport}/>
                    <PrivateRoute exact path="/report/customer_ledger_balance/" component={CustomerLedgerBalance}/>
                    <PrivateRoute exact path="/report/stock_register/" component={StockRegisterReport}/>
                    <PrivateRoute exact path="/report/stock_transfer/" component={StockTransferReport}/>
                    <PrivateRoute exact path="/report/incentive_register/" component={IncentiveRegisterReport}/>
                    <PrivateRoute exact path="/report/day_close/" component={DayCloseReport}/>
                    <PrivateRoute exact path="/report/ibt_register/" component={IbtRegisterReport}/>
                    <PrivateRoute exact path="/report/day_book/" component={DayBookRegisterReport}/>
                    <PrivateRoute exact path="/report/audit_log/" component={AuditLogReport}/>
                    <PrivateRoute exact path="/report/item_movement_register/" component={ItemMovementRegister}/>
                    <PrivateRoute exact path="/report/purchase_vat_register/" component={PurchaseVatRegisterReport}/>
                    <PrivateRoute exact path="/report/sales_vat_register/" component={SalesVatRegisterReport}/>
                    <PrivateRoute exact path="/report/sales_materialised_report/" component={SalesMaterialisedList}/>
                    <PrivateRoute exact path="/report/trial_balance/" component={TrialBalanceReport}/>
                    <PrivateRoute exact path="/report/profit_loss/" component={ProfitLossReport}/>
                    <PrivateRoute exact path="/report/balance_sheet/" component={BalanceSheetReport}/>
                    <PrivateRoute exact path="/report/ho_branch_ibt_reconciliation/" component={HoBranchIbtReconciliationReport}/>

                    <PrivateRoute exact path="/transaction/payment/create/" component={PaymentCreate}/>
                    <PrivateRoute exact path="/transaction/receipt/create/" component={ReceiptCreate}/>

                    <PrivateRoute exact path="/transaction/payment_voucher/:voucher_id/detail/" component={PaymentVoucherDetail}/>
                    <PrivateRoute exact path="/transaction/jbl_office_payment/create/" component={JblOfficePaymentCreate}/>
                    <PrivateRoute exact path="/transaction/stock_transfer/create/" component={StockTransferCreate}/>
                    <PrivateRoute exact path="/transaction/ibt/cash_bank_transfer/" component={IbtCashBankTransfer}/>
                    <PrivateRoute exact path="/stock_demand/" component={StockDemandList}/>
                    <PrivateRoute exact path="/utility/denomination_calculator/" component={DenominationCalculator}/>

                    <PrivateRoute exact path="/utility/notice/list/" component={NoticeList}/>
                    <PrivateRoute exact path="/utility/db_backup/list/" component={DatabaseBackupList}/>
                    <PrivateRoute exact path="/report/jbl/demand_loan_list/" component={JblHomeApplianceDemandLoanList}/>
                    <PrivateRoute exact path="/report/jbl/disbursed_loan_list/" component={JblHomeApplianceDisbursedLoanList}/>

                    <PrivateRoute exact path="/report/account/main_ledger/" component={AccountMainLedgerReport}/>
                    <PrivateRoute exact path="/report/account/sub_ledger/" component={AccountSubLedgerReport}/>
                    <PrivateRoute exact path="/report/customer_incentive/" component={CustomerIncentiveExpenseReport}/>
                    <PrivateRoute exact path="/report/jbl_bill_payment/" component={JblBillPaymentReport}/>

                    <PrivateRoute exact path="/item_demand/list/" component={ItemDemandList}/>
                    <PrivateRoute exact path="/party_confirmation_letter_print/" component={PartyConfirmationLetterPrint}/>
                    <PrivateRoute exact path="/item_order/list/" component={ItemOrderList}/>
                    <PrivateRoute exact path="/item_order_delivery/list/" component={ItemOrderDeliveryList}/>
                    <PrivateRoute exact path="/item_order/payment/list/" component={ItemOrderPaymentList}/>
                    <PrivateRoute exact path="/item_order/incentive/list/" component={ItemOrderIncentiveList}/>
                    <PrivateRoute exact path="/item_order/debtor_report/" component={ItemOrderDebtorReport}/>
                    <PrivateRoute exact path="/item_order/delivery_charge_report/" component={ItemOrderDeliveryChargeReport}/>

                    <Redirect to='/dashboard'/>
                  </Switch>
                </Content>

                {/*<Footer style={{fontSize: "13px", textAlign: 'center', background: "rgb(255, 255, 255", borderTop: "1px solid rgb(237, 237, 237)"}}>FinLiteX ©2019 Created by Sandip</Footer>*/}
                <Footer style={{fontSize: "13px", textAlign: 'center', borderTop: "1px solid rgb(237, 237, 237)"}}>Django Billing © 2024 Developed By JBS Technology</Footer>
              </Layout>
            </Layout>
          </Layout>
        </ConfigProvider>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  header: state.header,
  user: state.user,
  user_type: state.user.user.user_type,
  token: state.user.token,
  staff: state.user.staff,
  show_notice_modal: state.user.show_notice_modal,
  show_password_change_modal: state.user.show_password_change_modal,
  active_fiscal_year: state.user.fiscal_year,
  day_closure_bs_date: state.user.day_closure_bs_date,
  day_closure_day_name: state.user.day_closure_day_name,
  is_fetching_user: state.user.is_fetching_user,
});

export default connect(mapStateToProps)(DefaultLayout);
